import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';

// import { colors } from 'constants/theme';

const BackgroundSection = ({
  children,
  image = false,
  bgColor = false,
  className = '',
}) => {
  // console.log('Background Section: ', children, image, bgColor);

  const { childImageSharp } = image;

  return (
    <>
      {image ? (
        <BackgroundImage
          Tag="div"
          className={className}
          style={{ display: 'flex' }}
          backgroundColor={bgColor}
          {...childImageSharp}
        >
          <InnerWrapper>{children}</InnerWrapper>
        </BackgroundImage>
      ) : (
        <InnerWrapper backgroundColor={bgColor}>{children}</InnerWrapper>
      )}
    </>
  );
};

BackgroundSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  image: PropTypes.oneOfType([
    PropTypes.shape({
      childImageSharp: PropTypes.shape({}),
    }),
    PropTypes.bool,
  ]),
  bgColor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
};

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  min-height: 100%;
  background-color: ${props => props.backgroundColor || 'inherit'};
`;

const Background = styled(BackgroundSection)`
  width: 100%;
  min-height: 100%;
  background-position: bottom center;
  background-size: fit;
`;

export default Background;
