import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'hedron';

import Background from '~v2/elements/background';
import { Container } from './section.css';

const Section = ({
  left = false,
  right = false,
  center = false,
  boundsProps = {},
  containerProps = {},
}) => (
  <Container className="section" {...containerProps}>
    <Grid.Bounds
      className="container"
      direction={left || right ? 'horizontal' : 'vertical'}
      halign="center"
      {...boundsProps}
    >
      {(left || right) && !center && (
        <>
          <Grid.Box {...left.boxProps}>
            {left.backgroundProps ? (
              <Background {...left.backgroundProps}>{left.children}</Background>
            ) : (
              <>{left.children}</>
            )}
          </Grid.Box>
          <Grid.Box {...right.boxProps}>
            {right.backgroundProps ? (
              <Background {...right.backgroundProps}>
                {right.children}
              </Background>
            ) : (
              <>{right.children}</>
            )}
          </Grid.Box>
        </>
      )}

      {center && !left && !right && (
        <Grid.Box {...center.boxProps}>{center.children}</Grid.Box>
      )}
    </Grid.Bounds>
  </Container>
);

Section.propTypes = {
  left: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  right: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  center: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  boundsProps: PropTypes.object,
  containerProps: PropTypes.object,
};

export default Section;
