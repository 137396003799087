import styled from 'styled-components';
import { colors } from 'constants/theme';
// import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.section`
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  overflow: hidden;

  transition: background 250ms ease, color 250ms ease;

  background-color: ${props => props.bgColor || colors.white};

  color: ${props => {
    // console.log('Section bgColor:', props.bgColor);

    if (props.fontColor) {
      return props.fontColor;
    }

    switch (props.bgColor) {
      case colors.warm_grey:
        return colors.cool_grey;

      case colors.dusty_gold:
        return colors.white;

      default:
        return colors.trace_black;
    }
  }};
`;

export const InnerContainer = styled.div`
  background-color: ${({ bgColor }) => bgColor || ''};
  background-image: ${({ bgImage }) => {
    // console.log('InnerContainer bgImage:', bgImage);
    return bgImage && `url( '${bgImage}' ) no-repeat cover;`;
  }};
`;
